<template>
  <div>
    <div class="wrapper" @click="actMoreDetailsMobile">
      <video
        :id="'player_carousel_' + isActive"
        :src="database.sources[0]"
        :poster="database.thumb"
        class="top-carousel-class"
        v-if="playerActive && database.sources[0] && !isMobile"
        muted
        loop
      ></video>
      <div v-else class="height: fit-content; cursor: pointer;">
        <img class="carousel-poster-img" :src="database.thumb" />
      </div>

      <div class="hero-meta-description">
        <div class="title">
          <p> {{ database.title }}</p>
        </div>
        <div class="main-info">
          <div class="ua-rating" v-show="database.advisory">
            <p>{{ database.advisory }}</p>
          </div>
          <div class="publish-year" v-if="database.productionyear">
            <p>{{ database.productionyear }}</p>
          </div>
          <div class="duration" v-if="database.duration">
            <p>{{database.duration}}</p>
          </div>
        </div>
        <div class="sub-desc" v-if="database.description">
          <Description :contentDetails="database" :limit="250" :isReadMore="true"></Description>
        </div>
        <div class="main-genre">
          <div class="sub-genre1" v-if="database.category">
            <p>{{$t(database.category)}}</p>
          </div>
          <div class="sub-genre2" v-if="database.genre">
            <p>{{$t(database.genre)}}</p>
          </div>
          <div class="sub-genre3" v-if="database.contentlanguage">
            <p>{{$t(database.contentlanguage)}}</p>
          </div>
        </div>
        <div class="more-details">
          <p class="more-about"  @click="actMoreDetails">{{$t("More Details")}}</p>
          <CspaceWatchlistBtn :content="database"  class="changeSvg addtoWatchlist" :isSmall="true" />
        </div>
      </div>

      <div class="mute-unmute-btn" @click="ChangeMute"  v-if="playerActive && database.sources[0]  && !isMobile">
          <img :src="ismuted ? Mute : Unmute" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
export default {
  props: ["database", "isActive"],
  data() {
    return {
      playerActive: true,
      ismuted: true,
      Mute: require('@/assets/icons/mute.png'),
      Unmute: require('@/assets/icons/unmute.png'),
      isMobile: false,
      database:""
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 600 ?  true :  false,
    eventBus.$on('event-isMuted', () => {
      this.ismuted = true
    })
  },
  components: {
      CspaceWatchlistBtn: () => import(/* webpackChunkName: "watchlisbutton" */ "@/components/SvgImages/CspaceWatchlistBtn.vue"),
      Description: () => import(/* webpackChunkName: "watchlisbutton" */ "@/components/Common/description.vue")
  },
  methods: {
    actMoreDetailsMobile () {
      if (this.isMobile) {
        this.$router.push({name: 'detailPageEarth', params:{
        title: this.database.defaulttitle,
        contentId: this.database.objectid
      }})
      
      }
    },
    actMoreDetails () {
       console.log( "database", this.database.defaulttitle)
      this.$router.push({name: 'detailPageEarth', params:{
        title: this.database.defaulttitle ,
        contentId: this.database.objectid
      }})
    },

    spliceText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...'; 
    } else {
      return text; 
    }
  },
    
    ChangeMute() {
      this.ismuted = !this.ismuted;
      const video = document.getElementById("player_carousel_" + this.isActive);
      video.muted = !video.muted;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.wrapper {
  background: black;
  width: 100%;
  z-index: 1;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  video {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .carousel-poster-img {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
  }
}

.wrapper::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 105%;
  content: "";
  background-image: $clr-carousel-image-shadow;
}
.hero-meta-description {
  position: absolute;
  top: 12rem;
  z-index: 2;
  left: 3.2rem;
  opacity: 0.7;
  .title {
    width: 35rem;
    line-height: 2.5rem;
  }
}
.wrapper-slider-section {
  position: relative;
  margin-top: -6rem;
  z-index: 5;
  color: white;
}
.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}
.main-info {
  display: flex;
  gap: 1rem;
}
.main-genre {
  display: flex;
  gap: 1rem;
}
.hero-meta-description > div:not(:first-child) {
  margin-top: 1.5rem;
}
.main-info {
  font-size: 1rem;
}
.main-genre {
  font-size: 1rem;
  color: #ffffff;
}
.sub-desc {
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  p {
    width: 35rem;
  }
}
.main-genre .sub-genre2 p::before,
.main-genre .sub-genre3 p::before {
  content: "• ";
  position: relative;
  right: 0.4rem;
}

.ua-rating {
  background: #32373e;
  border-radius: 0.2rem;
  color: #ffffff;
}
.ua-rating,
.publish-year,
.duration {
  padding: 0.2rem;
}
.publish-year,
.duration {
  color: #aaaaaa;
}
.title {
  color: #ffffff;
  font-weight: 600;
  
  font-size: 2.5rem;
}

.more-details{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.more-about {
  background-color: #ffffff;
  color: #121212;
  padding: 0.4rem;
  width: 9rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-top: 2rem;
  cursor: pointer;
}
.addtoWatchlist{
  margin-top: 2rem;
}
.mute-unmute-btn {
  position: absolute;
  right: 4%;
  bottom: 50%;
  z-index: 999999;
  cursor: pointer;
  img {
    width: 40px !important;
  }
}

@media only screen and (max-width: 600px) {
   .hero-meta-description{
    display: none;
   }
   .wrapper{
    position: relative;
    top: 7rem;
   }
  }

  @media only screen and (max-width: 1200px) {
    .mute-unmute-btn {
      position: absolute;
      right: 4%;
      bottom: 50%;
    }

  }


</style>