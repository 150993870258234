<template>
    <p :id="contentDetails.objectid" class="desc-block">
      <span>{{ truncatedDescription }}</span>
      <span v-if="isReadMoreEnabled" @click.stop="toggleReadMore" class="more-less-tag">{{ expand ? 'Less' : 'More' }}</span>
    </p> 
  </template>
  
  <script>
  export default {
    name: "Description",
    data() {
      return {
        expand: false,
      };
    },
    props: {
      contentDetails: {
        type: Object,
        required: true,
      },
      limit: {
        type: Number,
        required: true,
      },
      isReadMore: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      isReadMoreEnabled() {
        return (
            this.isReadMore && (this.contentDetails.description?.length > this.limit || this.contentDetails.longdescription?.length > this.limit ||
          this.contentDetails.shortdescription?.length > this.limit)
        );
      },
      truncatedDescription() {
        if (this.expand) {
          return this.fullDescription;
        }
        if(this.contentDetails.description?.length > this.limit) {
          return this.contentDetails.description.slice(0, this.limit) + '...';
        }
        if (this.contentDetails.longdescription?.length > this.limit) {
          return this.contentDetails.longdescription.slice(0, this.limit) + '...';
        }
        if (this.contentDetails.shortdescription?.length > this.limit) {
          return this.contentDetails.shortdescription.slice(0, this.limit) + '...';
        }
        return this.fullDescription;
      },
      fullDescription() {
        return (
            this.contentDetails.description || this.contentDetails.longdescription || this.contentDetails.shortdescription || ''
        );
      },
    },
    methods: {
      toggleReadMore() {
        this.expand = !this.expand;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
    .desc-block {
        .description {
            font-size: 1rem;
            font-weight: 400;
            color: #ffffff;
        }
        .more-less-tag {
            font-size: 0.65rem;
            font-weight: 600;
            border: 0.1rem solid #777;
            border-radius: 0.2rem;
            padding: 0.1rem 0.4rem;
            cursor: pointer;
        }
    }
  </style>
  